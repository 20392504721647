import dayjs from "dayjs";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useEventListener from "@use-it/event-listener";
import { isCurrentDay } from "../index";
import { storeEvents } from "../../../store/events";
import "../../calendar/Calendar/Calendar.scss";
import { getEventsAPI, getCategoriesAPI } from "../../../api";
import { fetchedCategories } from "../../../store/categories";
import { DayAsTile } from "../Day";

function WeekSkeuomorphism({ initialDate, period, setSelectedDate, layoutOnClick, onMonthClick }) {
    const table = useSelector(state => state.settings.theme) === 'table';
    const initalMonth = dayjs(initialDate).month()
    const initialYear = dayjs(initialDate).year()
    const periods = [
        {
            weekdays: ['ПОНЕДЕЛЬНИК', 'ВТОРНИК', 'СРЕДА', 'ЧЕТВЕРГ']
        },
        {
            weekdays: ['ПЯТНИЦА', 'СУББОТА', 'ВОСКРЕСЕНЬЕ', 'ПОНЕДЕЛЬНИК']
        }
    ];
    console.log(dayjs(initialDate).locale('ru').startOf('week').format('DD-MM-YYYY'))
    const calculatePeriodDays = () => {
        let firstPeriodDays = {};
        let secondPeriodDays = {};

        for (let i = 0; i < 4; i++) {
            firstPeriodDays[i] = {
                date: dayjs(initialDate).locale('ru').startOf('week').add(i, 'day'),
                dayOfMonth: dayjs(initialDate).locale('ru').startOf('week').add(i, 'day').date(),
                isCurrentPeriod: true
            };
        }

        for (let i = 0; i < 4; i++) {
            secondPeriodDays[i] = {
                date: dayjs(initialDate).locale('ru').startOf('week').add(i + 4, 'day'),
                dayOfMonth: dayjs(initialDate).locale('ru').startOf('week').add(i + 4, 'day').date(),
                isCurrentPeriod: true
            };
        }
        return [firstPeriodDays, secondPeriodDays];
    }

    const days = calculatePeriodDays();
    const lastDay = Object.values(days[1])[Object.values(days[1]).length - 1];
    const themeColor = useSelector(state => state.settings.themeColor);
    const highlightedThematics = useSelector(state => state.thematics.highlightedThematics);

    const dispatch = useDispatch();

    useEffect(() => {
        getEventsAPI().then(res => {
            dispatch(storeEvents(res));
        }).catch(err => console.log(err));

        getCategoriesAPI().then(res => {
            dispatch(fetchedCategories(res.data));
        }).catch(err => console.log(err));


    }, []);

    let thematicDateRange = []
    const selectDateRange = (day) => {
        thematicDateRange.push(day)
    }

    const handleDateRangeSelection = (days) => {
        if (thematicDateRange.length) {
        }

    }

    let isThematicFirstDayOutside = false


    const isDateSelection = useSelector(state => state.modal.isDateSelection)

    const alternate = useSelector(state => state.settings.theme) === 'rounded' && period === 1;
    const showPastDays = useSelector(state => state.settings.showPastDays);
    const [copyKeyPressed, setCopyKeyPressed] = useState(false);
    const [cutKeyPressed, setCutKeyPressed] = useState(false);
    const [dragKeyPressed, setDragKeyPressed] = useState(false);
    const [pasteKeyPressed, setPasteKeyPressed] = useState(false);
    const [escapeKeyPressed, setEscapeKeyPressed] = useState(false);
    const keyPressedHandler = (e) => {
        if (e.key === 'c' || e.key === 'C' || e.key === 'с' || e.key === 'С') {
            setCopyKeyPressed(true);
        } else if (e.key === 'x' || e.key === 'X' || e.key === 'ч' || e.key === 'Ч') {
            setCutKeyPressed(true);
        } else if (e.key === 'v' || e.key === 'V' || e.key === 'м' || e.key === 'М') {
            setPasteKeyPressed(true);
        } else if (e.ctrlKey || e.metaKey) {
            setDragKeyPressed(true)
        } else if (e.key == 'Escape') {
            setEscapeKeyPressed(true)
        }
        else {
            setCopyKeyPressed(false);
            setCutKeyPressed(false);
            setPasteKeyPressed(false);
            setDragKeyPressed(false)
            setEscapeKeyPressed(false)
        }
    };
    // if (highlightedThematics != null) {
    //     days[0].date.isAfter(highlightedThematics?.date_from) ? isThematicFirstDayOutside = true : isThematicFirstDayOutside = false
    // }

    useEventListener('keydown', keyPressedHandler);
    useEventListener('keyup', (e) => {
        setCopyKeyPressed(false);
        setCutKeyPressed(false);
        setPasteKeyPressed(false);
        setDragKeyPressed(false)
        setEscapeKeyPressed(false)
    });



    return (<>
        {isThematicFirstDayOutside &&
            <div className="thematic-outside" >
                <span dangerouslySetInnerHTML={{ __html: `Тематика: ${highlightedThematics.name}` }}>
                </span>
                <br />

                <span dangerouslySetInnerHTML={{ __html: `Старт:${dayjs(highlightedThematics.date_from).format('DD-MM-YYYY')}` }}>
                </span>

            </div>
        }
        {
            isDateSelection && <div className="thematic-date-selection"></div>
        }
        <div
            className={classNames({
                'month-and-week-number': true,
                'blue-gradient': themeColor === 'blue',
                'green-gradient': themeColor === 'green',


            })}
            onClick={() => onMonthClick(2, initalMonth, initialYear)}>
            <span className="month-and-week-number--span">
                Неделя - {dayjs(initialDate).week()}
            </span>
            <span className="month-and-week-number--span">
                {dayjs(initialDate).locale('ru').format('MMMM').toUpperCase()}
            </span>

            <span className="month-and-week-number--span">
                {dayjs(initialDate).year()}
            </span>
        </div >
        <div style={{ minHeight: '100%' }}>
            {days.map((day, i) => {
                return (
                    <>
                        <table
                            style={{ width: '100%', height: '100%' }}
                            key={i}
                            className={classNames({
                                'table-view': table,
                                'alternate-view': alternate,
                                'modern-view': !table && !alternate,

                            })}>

                            <thead>

                                <tr>
                                    {periods[i].weekdays.map((weekDay, index) => {
                                        return (
                                            <th key={index}
                                                onClick={() => setSelectedDate(day[index].date)}
                                                className={classNames({
                                                    'weekday': true,
                                                    'current-weekday': isCurrentDay(dayjs(day[index].date)),
                                                    'low-opacity': dayjs(day[index].date, 'day').isSame(lastDay.date, 'day'),
                                                    'blue-gradient': themeColor === 'blue',
                                                    'green-gradient': themeColor === 'green',
                                                })}>
                                                <span className="weekday--span">{weekDay}</span>
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                >
                                    {Object.entries(days[i]).map(([key, day], index) => {
                                        return (
                                            <DayAsTile key={index} onMouseOver={() => handleDateRangeSelection()}
                                                onClick={() => selectDateRange(day.date)}
                                                period={period} day={day}
                                                alternate={alternate}
                                                table={table}
                                                isSameDate={event => dayjs(event.date).isSame(day.date, 'day')}
                                                copyKeyPressed={copyKeyPressed}
                                                cutKeyPressed={copyKeyPressed}
                                                pasteKeyPressed={pasteKeyPressed}
                                                isDateSelection={isDateSelection}
                                                escapeKeyPressed={escapeKeyPressed}
                                                dragKeyPressed={dragKeyPressed}
                                                isLoweredOpacity={dayjs(day.date, 'day').isSame(lastDay.date, 'day')}
                                                maxRows={4}

                                            />

                                        )
                                    }
                                    )}
                                </tr>

                            </tbody>

                        </table>
                    </>

                )
            })}
        </div>
    </>)
}

export default WeekSkeuomorphism;